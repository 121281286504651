import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Container, makeStyles } from "@material-ui/core"

import { useHistory } from "react-router-dom"

import SEO from "../components/seo"

const backendUrl = `${process.env.GATSBY_API_URL}`

const documentGlobal = typeof document !== `undefined` && document
const windowGlobal = typeof window !== "undefined" && window

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
}))

const LoginWithGoogle = ({ props, pageContext }) => {
  const [text, setText] = useState("Loading...")
  const classes = useStyles()
  const { i18n } = useTranslation()
  const history = useHistory()

  const { providerName } = pageContext

  const searchedData = documentGlobal && documentGlobal.location.search

  useEffect(() => {
    fetch(
      `${backendUrl}/auth/${providerName}/callback${
        documentGlobal && documentGlobal.location.search
      }`
    )
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`)
        }
        return res
      })
      .then(res => res.json())
      .then(res => {
        if (windowGlobal) {
          windowGlobal.sessionStorage.setItem("jwtCode", res.jwt)
          windowGlobal.sessionStorage.setItem("userRl", res.user.role.name)
          windowGlobal.sessionStorage.setItem("userId", res.user.id)
        }
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        )
        if (windowGlobal) {
          setTimeout(() => (windowGlobal.location = "/"), 1000)
        }
      })
      .catch(err => {
        setText("An error occurred, please see the developer console.")
      })
  }, [history, searchedData, providerName])

  return (
    <>
      <SEO title="Login" lang={i18n.language} />
      <Container className={classes.mainContainer}>
        <Typography variant="h6" component="h2">
          {text}
        </Typography>
      </Container>
    </>
  )
}

export default LoginWithGoogle
